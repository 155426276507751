export default [
  {
    path: '/mentor-portal/startups',
    name: 'mentor-startups',
    component: () => import('@/views/mentor-portal/ManageStartup.vue'),
    meta: {
      pageTitle: 'Manage Startups',
      breadcrumb: [
        {
          text: 'Startups',
          active: true,
        },
      ],
    },
  },
  {
    path: '/mentor-portal/startups/add',
    name: 'add-startups',
    component: () => import('@/views/incubator-portal/startups/pages/AddStartups.vue'),
    meta: {
      pageTitle: 'Add Startup',
      breadcrumb: [
        {
          text: 'Startups',
          to: { name: 'manage-startups' },
        },
        {
          text: 'Add Startup',
          active: true,
        },
      ],
    },
  },
  {
    path: '/mentor-portal/startups/:id',
    name: 'view-startups',
    component: () => import('@/views/incubator-portal/startups/pages/AddStartups.vue'),
    meta: {
      pageTitle: 'Update Startup',
      breadcrumb: [
        {
          text: 'Startups',
          to: { name: 'manage-startups' },
        },
        {
          text: 'Update Startup',
          active: true,
        },
      ],
    },
  },
  {
    path: '/mentor-portal/startups/:id/:sname/manage',
    name: 'startups-manager',
    component: () => import('@/views/incubator-portal/startups/pages/startup-manager/index.vue'),
    meta: {
      pageTitle: 'Startup Manager',
      breadcrumb: [
        {
          text: 'Startups',
          to: { name: 'manage-startups' },
        },
        {
          text: 'Startup Manager',
          active: true,
        },
      ],
    },
  },
  {
    path: '/mentor-portal/startups/:id/:sname/about',
    name: 'about-startup',
    component: () => import('@/views/incubator-portal/startups/pages/startup-manager/About.vue'),
    meta: {
      pageTitle: 'About Startup',
      breadcrumb: [
        {
          text: 'Startups',
          to: { name: 'manage-startups' },
        },
        {
          text: 'Startup Manager',
          active: true,
        },
      ],
    },
  },
  {
    path: '/mentor-portal/startups/:id/:sname/agreements',
    name: 'startup-agreements',
    component: () => import('@/views/incubator-portal/startups/pages/startup-manager/Agreements.vue'),
    meta: {
      pageTitle: 'Startup Agreements',
      breadcrumb: [
        {
          text: 'Startups',
          to: { name: 'manage-startups' },
        },
        {
          text: 'Startup Manager',
          active: true,
        },
      ],
    },
  },
  {
    path: '/mentor-portal/startups/:id/:sname/performance',
    name: 'startup-performance',
    component: () => import('@/views/incubator-portal/startups/pages/startup-manager/Performance.vue'),
    meta: {
      pageTitle: 'Startup Performance',
      breadcrumb: [
        {
          text: 'Startups',
          to: { name: 'manage-startups' },
        },
        {
          text: 'Startup Manager',
          active: true,
        },
      ],
    },
  },
  {
    path: '/mentor-portal/startups/:id/milestone/:mid/stages',
    name: 'startups-milestone-stages',
    component: () => import('@/views/incubator-portal/startups/pages/startup-manager/milestone-manager/MilestoneStages.vue'),
    meta: {
      pageTitle: 'Milestone Stages',
      breadcrumb: [
        {
          text: 'Startups',
          to: { name: 'manage-startups' },
        },
        {
          text: 'Startup Manager',
        },
        {
          text: 'Milestone Manager',
          active: true,
        },
      ],
    },
  },
  {
    path: '/mentor-portal/startups/:id/milestone/stages/:sid/review',
    name: 'startups-review-questions',
    component: () => import('@/views/incubator-portal/startups/pages/startup-manager/milestone-manager/StageReviewQuestions.vue'),
    meta: {
      pageTitle: 'Review Questions',
      breadcrumb: [
        {
          text: 'Startups',
          to: { name: 'manage-startups' },
        },
        {
          text: 'Startup Manager',
        },
        {
          text: 'Milestone Manager',
          active: true,
        },
      ],
    },
  },
  {
    path: '/mentor-portal/startups/:id/milestone/stages/:sid/evaluations',
    name: 'startups-review-evaluations',
    component: () => import('@/views/incubator-portal/startups/pages/startup-manager/milestone-manager/StageReviewEvaluations.vue'),
    meta: {
      pageTitle: 'Stage Evaluations',
      breadcrumb: [
        {
          text: 'Startups',
          to: { name: 'manage-startups' },
        },
        {
          text: 'Startup Manager',
        },
        {
          text: 'Milestone Manager',
          active: true,
        },
      ],
    },
  },
]
