import Vue from 'vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { getUserData } from '@/auth/utils'
import gql from 'graphql-tag'
import apiConfig from '@/config'

Vue.mixin({
  methods: {
    // Show a toast notification
    showAlert(title, type = 'primary', text = null) {
      const icons = {
        warning: 'AlertTriangleIcon',
        success: 'CheckCircleIcon',
        danger: 'XCircleIcon',
        info: 'InfoIcon',
        primary: 'InfoIcon',
      }
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon: icons[type],
          variant: type,
          text,
        },
      })
    },
    // Add https:// to the link if it doesn't have it
    prependHttps(link) {
      if (!link) {
        return ''
      }
      if (link.indexOf('http://') === 0 || link.indexOf('https://') === 0) {
        return link
      }
      return `https://${link}`
    },
    // Compare two objects and create a list of updates, additions and deletions by id
    listObjectChanges(oldObj, newObj, idKey = 'id') {
      // Items without idKey are considered new
      const added = newObj.filter(item => !oldObj.find(i => i[idKey] === item[idKey]))
      // Items not in the new list are considered deleted
      const deleted = oldObj.filter(item => !newObj.find(i => i[idKey] === item[idKey]))
      // Items with the same idKey are compared
      const updated = []

      newObj.forEach(item => {
        // Find object with same idKey
        const oldItem = oldObj.find(i => i[idKey] === item[idKey])
        if (!oldItem) {
          return
        }
        // Compare the two objects
        const changes = Object.keys(item).filter(key => item[key] !== oldItem[key])
        // If there are changes, add the item to the updated list
        if (changes.length > 0) {
          updated.push(item)
        }
      })

      return { updated, added, deleted }
    },
    // Return incubator domain (as in DB)
    getIncubatorFromSubdomain() {
      const urlPrefix = window.location.host.split('.')[0].toLowerCase()
      // If URL is an IP address, return null
      if (!urlPrefix.match(/^[a-z0-9]+$/i)) {
        return null
      }
      //   If URL urlPrefix is localhost, starworks, test, demo, return null
      if (urlPrefix === 'localhost' || urlPrefix === 'starworks' || urlPrefix === 'test' || urlPrefix === 'demo') {
        return null
      }
      return urlPrefix
    },
    // Get selected User / Organisation
    // If associatedOrgDetails have more than 1 entry, get the first entry, else return user_id
    getSelectedEntity() {
      const associatedOrgs = getUserData().associatedOrgDetails
      if (associatedOrgs.length > 0) {
        return {
          user_id: null,
          org_id: associatedOrgs[0].organization_id,
        }
      }
      return {
        user_id: getUserData().id,
        org_id: null,
      }
    },
    createNotification({
      templateName,
      variables = {},
      linkRouteParams = {},
      fromOrgId,
      fromUserId,
      toOrgId,
      toUserId,
    }) {
      this.$apollo.mutate({
        mutation: gql`
          mutation createNotification($template_name: String!, $variables: jsonb, $link_route_params: jsonb, $from_org_id: Int, $from_user_id: Int, $to_org_id: Int, $to_user_id: Int) {
            insert_notifications_log_table_one(object: {notifications_template_name: $template_name, variables: $variables, link_route_params: $link_route_params, from_org_id: $from_org_id, from_user_id: $from_user_id, to_org_id: $to_org_id, to_user_id: $to_user_id}) {
              notifications_log_id
            }
          }
        `,
        variables: {
          template_name: templateName,
          variables,
          link_route_params: linkRouteParams,
          from_org_id: fromOrgId,
          from_user_id: fromUserId,
          to_org_id: toOrgId,
          to_user_id: toUserId,
        },
      })
    },
    getLinkFromS3(key) {
      return fetch(`${apiConfig.GET_S3_FILE_API_URL}/?file_key=${key}`, {
        method: 'GET',
      }).then(response => response.json())
        .then(data => data.url)
    },
  },
})
